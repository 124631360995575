<template>
  <page-content>

    <common-table
        ref="table"
        path="web/device-company"
        :columns="columns"
        :pageable="false"
    >

      <template slot="search">
        <a-button ghost type="primary" @click="add">新增分公司</a-button>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-edit @click="edit(record)"></action-edit>
        <action-delete @click="remove(record)"></action-delete>
      </template>
    </common-table>

    <CompanyEdit ref="editDrawer" @success="getList"></CompanyEdit>
  </page-content>
</template>

<script>

import CompanyEdit from './CompanyEdit'

export default {
  components: {
    CompanyEdit
  },
  data() {
    return {
    }
  },

  computed: {
    columns() {
      return [
        {
          title: '分公司名称',
          dataIndex: 'data.name'
        },
        {
          title: '创建时间',
          dataIndex: 'data.createdAt',
        },
        {
          title: '更新时间',
          dataIndex: 'data.updatedAt',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }
      ]
    }
  },
  methods: {
    add() {
      this.$refs.editDrawer.show()
    },
    edit(record) {
      this.$refs.editDrawer.show(record.data)
    },
    remove(record) {
      let that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk() {
          that.$delete('web/device-company/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },
    getList() {
      this.$refs.table.getData()
    }
  }
}
</script>

<style scoped>

</style>
